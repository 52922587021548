import React, { FC } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { DesignButton } from '../../components/DesignButton';
import { usePathPrefix } from '../../../../../constants/hooks';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';

interface IProps {
  title: string;
  showModal: () => void;
  setModalTitle: (value: string) => void;
}

const DesignTitle: FC<IProps> = ({ title, showModal, setModalTitle }) => {
  const { formatMessage } = useIntl();
  const prefix = usePathPrefix();

  const onClick = () => {
    setModalTitle('cases.design.modalTitle');
    showModal();
  };
  return (
    <div
      className={classNames(styles.title)}
      style={{ backgroundImage: `url(${prefix}/case/design/fon.png)` }}
    >
      <div className={classNames(styles.title__container)}>
        <div
          className={classNames(root.breadcrumbs, styles.title__breadcrumbs)}
        >
          <div className={styles.breadcrumbs}>
            <Breadcrumbs title={title} />
          </div>
          <div className={styles.link}>
            <a
              className={styles.phone}
              href={`tel:${formatMessage({ id: 'cases.design.title.phone' })}`}
            >
              {t('cases.design.title.phone')}
            </a>
            <a
              href={`mailto:${formatMessage({
                id: 'cases.design.title.email',
              })}`}
            >
              {t('cases.design.title.email')}
            </a>
          </div>
        </div>
        <div className={styles.title__text_block}>
          <h3 className={styles.title__text_block__title}>
            {t('cases.design.title.title')}
          </h3>
          <p className={styles.title__text_block__desc}>
            {t('cases.design.title.desc')}
          </p>
        </div>
        <DesignButton onClick={onClick}>
          {t('cases.design.title.button')}
        </DesignButton>
      </div>
    </div>
  );
};

export { DesignTitle };
