import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { ReviewCard } from '../../../../reviews/ReviewCard';
import { IReviewsQueryEdge } from '../../../../../queries/reviews/types';
import { t } from '../../../../../i18n';

interface IProps {
  reviews: IReviewsQueryEdge[];
  findReviewCase?: (node: IReviewsQueryEdge['node']) => string;
  locale: string;
}

const DesignReviews: FC<IProps> = ({ reviews, locale, findReviewCase }) => {
  const [reviewNumber, setReviewNumber] = useState(0);
  const goForward = () => {
    if (reviewNumber < reviews.length - 1) {
      setReviewNumber(reviewNumber + 1);
    }
  };

  const goBack = () => {
    if (reviewNumber > 0) {
      setReviewNumber(reviewNumber - 1);
    }
  };

  return (
    <div className={styles.reviews}>
      <div className={styles.reviews__title}>
        {t('cases.design.reviews.title')}
        <div className={styles.reviews__arrows}>
          <div
            role="button"
            tabIndex={0}
            className={
              reviewNumber === 0 ? styles.reviews__arrows__disabled : ''
            }
            onClick={goBack}
            onKeyDown={goBack}
          >
            <img src="/case/design/arrow-left.svg" alt="" />
          </div>
          <div
            role="button"
            tabIndex={0}
            className={
              reviewNumber === reviews.length - 1
                ? styles.reviews__arrows__disabled
                : ''
            }
            onClick={goForward}
            onKeyDown={goForward}
          >
            <img src="/case/design/arrow-right.svg" alt="" />
          </div>
        </div>
      </div>
      <ReviewCard
        locale={locale}
        node={reviews[reviewNumber].node}
        key={reviews[reviewNumber].node.fileAbsolutePath}
        caseUrl={
          findReviewCase ? findReviewCase(reviews[reviewNumber].node) : ''
        }
      />
    </div>
  );
};
export default DesignReviews;
