import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface PortfolioCardStaticProps {
  imgSource: string;
  href: string;
}

const PortfolioCardStatic: FC<PortfolioCardStaticProps> = ({
  imgSource,
  href,
}) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className={styles.portfolioCard}
  >
    <img src={imgSource} alt="" className={styles.portfolioCard__image} />
  </a>
);

const DesignPortfolioStatic = () => {
  const cardsDetails = [
    {
      img: '/case/design/portfolio/sherlock.png',
      link: 'https://www.behance.net/gallery/134830761/Investment-App-MVP',
    },
    {
      img: '/case/design/portfolio/rent-bike.png',
      link: 'https://www.behance.net/gallery/114163905/RENT-A-MOTORCYCLE-MOBILE-APP',
    },
    {
      img: '/case/design/portfolio/primetime.png',
      link: 'https://www.behance.net/gallery/79282857/PrimeTime-Coffee-UIUX-App',
    },
    {
      img: '/case/design/portfolio/nikolife.png',
      link: 'https://www.behance.net/gallery/112092247/Nikolife-Healthy-Food-App',
    },
    {
      img: '/case/design/portfolio/electro.png',
      link: 'https://www.behance.net/gallery/115059101/Electricity-Safe-Guard-Mobile-App',
    },
    {
      img: '/case/design/portfolio/alliance-trucks.png',
      link: 'https://www.behance.net/gallery/114567781/Alliance-Trucks-App',
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('cases.design.portfolio.title')}</div>
      <div className={styles.imagesContainer}>
        {cardsDetails.map((card, i) => (
          <PortfolioCardStatic
            key={`card-static-${i + 1}`}
            imgSource={card.img}
            href={card.link}
          />
        ))}
      </div>
    </div>
  );
};

export default DesignPortfolioStatic;
