import React, { FC } from 'react';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { Modal } from '../../../../main/Modal';
import { t } from '../../../../../i18n';
import { Button } from '../../../../main/Button';

interface IProps {
  onClose: () => void;
}

const DesignSuccessModal: FC<IProps> = ({ onClose }) => {
  const prefix = usePathPrefix();

  return (
    <Modal onClose={onClose}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <img src={`${prefix}/vacancy/success.svg`} alt="success" />
        </div>

        <h1>{t('vacancies.success_title')}</h1>

        <div className={styles.text}>{t('vacancies.success_text')}</div>

        <Button onClick={onClose}>{t('vacancies.ok')}</Button>
      </div>
    </Modal>
  );
};

export { DesignSuccessModal };
