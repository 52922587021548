import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {}

interface PromiseProps {
  backgroundColor: string;
  img: string;
  titleColored: string;
  titleRest: string;
  textStartOne: string;
  linkOneUrl?: string | undefined;
  linkOneText?: string | undefined;
  textEndOne?: string | undefined;
  textStartTwo?: string | undefined;
  textBoldTwo?: string | undefined;
  textEndTwo?: string | undefined;
  textStartThree?: string | undefined;
  specialStyle?: boolean | undefined;
}

const Promise: FC<PromiseProps> = ({
  backgroundColor,
  img,
  titleColored,
  titleRest,
  textStartOne,
  linkOneUrl,
  linkOneText,
  textEndOne,
  textStartTwo,
  textBoldTwo,
  textEndTwo,
  textStartThree,
  specialStyle,
}) => (
  <div className={styles.promise} style={{ backgroundColor }}>
    <div className={styles.promise__content}>
      <div className={styles.promise__title}>
        <img src={img} alt="" className={styles.promise__image} />
        <div
          className={specialStyle ? styles.promise__titleWrapper : undefined}
        >
          <span className={styles.promise__title__colored}>
            {t(titleColored)}
          </span>
          <span>{t(titleRest)}</span>
        </div>
      </div>
      <div className={styles.promise__text}>
        {t(textStartOne)}
        {linkOneUrl && linkOneText && textEndOne && (
          <>
            <a
              href={linkOneUrl}
              target="_blank"
              rel="noreferrer"
              className={styles.promise__text__link}
            >
              {t(linkOneText)}
            </a>
            {t(textEndOne)}
          </>
        )}
      </div>
      {textStartTwo && (
        <div className={styles.promise__text}>
          {t(textStartTwo)}
          {textEndTwo && textBoldTwo && (
            <>
              <strong>{t(textBoldTwo)}</strong>
              {t(textEndTwo)}
            </>
          )}
        </div>
      )}
      {textStartThree && (
        <div className={styles.promise__text}>{t(textStartThree)}</div>
      )}
    </div>
  </div>
);

const items = [
  { icon: '/case/design/vision.svg', color: '#46368C', paragraphs: 2 },
  { icon: '/case/design/idea.svg', color: '#4D3E91', paragraphs: 1 },
  {
    icon: '/case/design/history.svg',
    color: '#554695',
    paragraphs: 2,
    bold: true,
  },
  { icon: '/case/design/palette.svg', color: '#5C4E9A', paragraphs: 2 },
  { icon: '/case/design/leaderboard.svg', color: '#64569E', paragraphs: 3 },
  {
    icon: '/case/design/crop_rotate.svg',
    color: '#6B5EA3',
    paragraphs: 1,
    linkOne: 'https://prototyping.icerockdev.com/',
    specialStyle: true,
  },
];

const DesignPromises: FC<IProps> = () => (
  <div>
    {items.map((item, index) => (
      <Promise
        key={`design-promise-${index + 1}`}
        img={item.icon}
        backgroundColor={item.color}
        titleColored={`cases.design.promises.promise_${index}.title.colored`}
        titleRest={`cases.design.promises.promise_${index}.title.rest`}
        textStartOne={`cases.design.promises.promise_${index}.paragraph_0.textStart`}
        linkOneUrl={item.linkOne ? item.linkOne : undefined}
        linkOneText={
          item.linkOne
            ? `cases.design.promises.promise_${index}.paragraph_0.textLink`
            : undefined
        }
        textEndOne={
          item.linkOne
            ? `cases.design.promises.promise_${index}.paragraph_0.textEnd`
            : undefined
        }
        textStartTwo={
          item.paragraphs >= 2
            ? `cases.design.promises.promise_${index}.paragraph_1.textStart`
            : undefined
        }
        textBoldTwo={
          item.bold
            ? `cases.design.promises.promise_${index}.paragraph_1.textBold`
            : undefined
        }
        textEndTwo={
          item.bold
            ? `cases.design.promises.promise_${index}.paragraph_1.textEnd`
            : undefined
        }
        textStartThree={
          item.paragraphs >= 3
            ? `cases.design.promises.promise_${index}.paragraph_2.textStart`
            : undefined
        }
        specialStyle={item.specialStyle}
      />
    ))}
  </div>
);

export default DesignPromises;
