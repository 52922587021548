import React from 'react';
import { useIntl } from 'react-intl';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';

const DesignFooter = () => {
  const { formatMessage } = useIntl();
  const prefix = usePathPrefix();

  return (
    <div className={styles.wrap}>
      <div>
        <img src={`${prefix}/case/design/LogoIceRock.svg`} alt="" />
      </div>
      <div className={styles.links}>
        <p className={styles.link}>
          <a href={`tel:${formatMessage({ id: 'cases.design.footer.phone' })}`}>
            {t('cases.design.footer.phone')}
          </a>
        </p>
        <p className={styles.link}>
          <a
            href={`mailto:${formatMessage({
              id: 'cases.design.footer.email',
            })}`}
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            {t('cases.design.footer.email')}
          </a>
        </p>
        <div className={styles.social_links}>
          <div className={styles.clutch_link}>
            <a
              href="https://clutch.co/profile/icerock-development#summary"
              target="_blank"
              rel="noreferrer"
            >
              <img src={`${prefix}/case/design/Clutch.svg`} alt="" />
            </a>
          </div>
          <div className={styles.social_link}>
            <a
              href="https://www.behance.net/IceRock"
              target="_blank"
              rel="noreferrer"
            >
              <img src={`${prefix}/case/design/behance.svg`} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export { DesignFooter };
