import React, { FC, ReactElement } from 'react';
import styles from './styles.module.scss';

interface IProps {
  img: string;
  title: ReactElement<any, any> | null;
}

const DesignReasonsCard: FC<IProps> = ({ img, children, title }) => {
  const getDescStyle = () => {
    if (!title) {
      return `${styles.card__desc} ${styles.card__marginTop}`;
    }
    return `${styles.card__desc}`;
  };
  return (
    <div className={styles.card}>
      <img src={img} alt="" className={styles.card__image} />
      {title && <div className={styles.card__title}>{title}</div>}
      <div className={getDescStyle()}>{children}</div>
    </div>
  );
};

export { DesignReasonsCard };
