import React, { FC } from 'react';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { t } from '../../../../../i18n';
import { usePathPrefix } from '../../../../../constants/hooks';
import { DesignReasonsCard } from '../../components/DesignReasonsCard';

interface IProps {}

const DesignReasons: FC<IProps> = () => {
  const prefix = usePathPrefix();

  return (
    <div className={styles.reasons}>
      <div className={root.container}>
        <div className={styles.reasons__cards}>
          <DesignReasonsCard
            img={`${prefix}/case/design/inventory.svg`}
            title={null}
          >
            {t('cases.design.reasons.reasons_1.desc')}
          </DesignReasonsCard>

          <DesignReasonsCard
            img={`${prefix}/case/design/group.svg`}
            title={null}
          >
            {t('cases.design.reasons.reasons_2.desc')}
          </DesignReasonsCard>

          <DesignReasonsCard
            img={`${prefix}/case/design/research.svg`}
            title={null}
          >
            {t('cases.design.reasons.reasons_3.desc')}
          </DesignReasonsCard>
        </div>
      </div>
    </div>
  );
};

export { DesignReasons };
