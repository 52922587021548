import React, { FC, useCallback, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { DesignTitle } from '../../../../components/directions/design/screens/DesignTitle';
import { DesignReasons } from '../../../../components/directions/design/screens/DesignReasons';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { DesignFooter } from '../../../../components/directions/design/screens/DesignFooter';
import DesignPromises from '../../../../components/directions/design/screens/DesignPromises';
import { IReviewsQuery } from '../../../../queries/reviews/types';
import { ICaseQuery } from '../../../../queries/cases/types';
import { IHeaderQuery } from '../../../../queries/headers/types';
import DesignReviews from '../../../../components/directions/design/screens/DesignReviews';
import DesignPortfolioStatic from '../../../../components/directions/design/screens/DesignPortfolioStatic';
import { DesignContactModal } from '../../../../components/directions/design/screens/DesignContactModal';
import { DesignSuccessModal } from '../../../../components/directions/design/components/DesignSuccessModal';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery & IReviewsQuery & IHeaderQuery & ICaseQuery,
  { locale: string; tag: string; page: number }
>;

const DesignPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'en', tag = '', page = 0 },
}) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const [modalTitle, setModalTitle] = useState('Заявка на дизайн');

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );

  const design = direction?.node.frontmatter;

  const reviews = useMemo(
    () => data.reviews.edges.filter((edge) => edge.node),
    [data, locale]
  );

  return (
    <ReCaptchaProvider>
      <MainLayout
        locale={locale}
        title={design?.seo?.title}
        description={design?.seo?.description}
        keywords={design?.seo?.keywords}
      >
        <DesignTitle
          title={design?.name ?? ''}
          showModal={toggleModal}
          setModalTitle={setModalTitle}
        />
        <DesignReasons />
        <DesignPromises />
        <DesignPortfolioStatic />
        <DesignReviews reviews={reviews} locale={locale} />
        <DesignFooter />

        {isModalShown && (
          <DesignContactModal
            onClose={toggleModal}
            onSuccess={toggleSuccess}
            titleType={modalTitle}
          />
        )}
        {isSuccessShown && <DesignSuccessModal onClose={toggleSuccess} />}
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }

    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/header/header/" } }
    ) {
      ...HeaderFields
    }

    reviews: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/reviews/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...ReviewFields
    }
  }
`;

export default DesignPage;
